/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import Download from '@mui/icons-material/Download';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Fade, Grid, IconButton, Paper, Slider, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { useSmallScreen } from '../../hooks';
import { Nullable } from '../../types';
import { downloadFile, getSignedUrl } from '../../util';
import { ErrorMessage } from '../error-message';
import { FlexBox } from '../flex-box';
import { MiddleSpinner } from '../middle-spinner';
import { VideoComponent } from './video-component';
import { videoPlayerStyles } from './video-player.styles';
import { RowButtonProps, RowItemProps, VideoPlayerProps } from './video-player.types';

const StyledSlider = styled(Slider)({
  color: '#ffffff',
  '& .MuiSlider-thumb': {
    display: 'none',
  },
  '& .MuiSlider-track': {
    height: '.1em',
  },
  '& .MuiSlider-rail': {
    height: '.5em',
    borderRadius: 2,
  },
});

const toHHMMSS = (input: number): string => {
  const appendZero = (val: number): string => val < 10 ? `0${val}` : val.toString();
  const minute = Math.floor((input / 60));
  const second = Math.floor(input) % 60;
  return `${minute}:${appendZero(second)}`;
};

const RowItem = (
  { children, style }: RowItemProps,
): JSX.Element => (
  <Grid item textAlign="center" style={style || { lineHeight: '2em' }}>
    {children}
  </Grid>
);

const RowButtons = ({ children, disabled, handler, ...props }: RowButtonProps): JSX.Element => (
  <IconButton data-testid={props['data-testid']} disabled={disabled || false} onClick={handler} style={{ padding: '.125em', color: '#fff' }}>
    {children}
  </IconButton>
);

export const VideoPlayer: FC<VideoPlayerProps> = ({
  controls = {
    close: true,
    export: true,
    fullScreen: true,
    pause: true,
    play: true,
    seek: true,
    volume: true,
  },
  fadeTimer = 500,
  fileName,
  fixedSize = false,
  height = 'auto',
  onExport,
  onFullScreen = (fullscreen: boolean): void => { if (fullscreen) return; },
  onModalClick = () => { },
  onMuted = (muted: boolean): void => { if (muted) return; },
  onPause = () => { },
  onPlay = () => { },
  onSeek = (seeked: number) => { if (seeked) return; },
  open = true,
  url,
  width = '100%',
  ...props
}: VideoPlayerProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [duration, setDuration] = useState<number>(0);
  const [error, setError] = useState<Nullable<Error>>(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [mute, setMute] = useState(true);
  const [play, setPlay] = useState(true);
  const [canPlay, setCanPlay] = useState(false);
  const [position, setPosition] = useState<number>(0);
  const [downloading, setDownloading] = useState(false);
  const { axios } = useAuthenticated();
  const { t } = useTranslation(['common']);
  const { classes } = videoPlayerStyles();
  const smallScreen = useSmallScreen();
  const videoElement = useRef<HTMLVideoElement>(null);
  const videoWrapper = useRef<HTMLDivElement>(null);
  const video = videoElement.current;

  if (!url && !error) setError(new Error(t('common:common.hint.invalid-url')));

  const EXPORT_DEFAULT = useCallback(async () => {
    const { data } = await getSignedUrl(axios, url);
    if (!data.signedUrl) return setError(t('common:component.video-modal.hint.download-error'));
    setDownloading(true);
    const videoFile = fileName ?? new URL(data.signedUrl).pathname.replace(/^.*\//gm, '');
    const downloadMessage = enqueueSnackbar(t('common:component.video-player.hint.downloading'), { variant: 'info', persist: true, preventDuplicate: true });
    const handleError = (): void => setError(t('common:component.video-modal.hint.download-error'));
    const handleCallBack = (): void => { closeSnackbar(downloadMessage); setDownloading(false); };
    downloadFile(data.signedUrl, videoFile, handleError, handleCallBack,
    );
  }, [url]);

  const playVideo = (): void => {
    if (video?.ended) video?.load();
    if (video?.paused && play) {
      video.play().catch(() => {
        setError(new Error(t('common:component.video-modal.hint.player-error')));
      });
    }
  };

  const playHandler = (): void => {
    if (play && controls.play) playVideo();
    else if (controls.pause) video?.pause();
  };
  const muteHandler = (): void => {
    if (!video || !controls.volume) return;

    video.muted = !mute;
    onMuted(!mute);
    setMute(!mute);
  };

  const onVideoSeek = (_: Event, seekTo: number | number[]): void => {
    if (!video || !controls.seek) return;
    void onSeek(typeof seekTo !== 'number' ? seekTo[0] : seekTo);
    video.currentTime = typeof seekTo !== 'number' ? seekTo[0] : seekTo;
  };

  const fullScreen = (): void => {
    if (!videoWrapper.current || !controls.fullScreen) return;
    onFullScreen(!fullscreen);
    setFullscreen(!fullscreen);
    if (fullscreen && document.fullscreenElement) void document.exitFullscreen();
    else if (!fullscreen) void videoWrapper.current.requestFullscreen();
  };

  const onClick = (): void => {
    if (!video) return;
    if (play && controls.play) playVideo();
    else if (!play && controls.pause) video.pause();
  };

  const onVideoPlaying = (): void => { setPlay(false); onPlay(); };
  const onVideoPause = (): void => { setPlay(true); onPause(); };
  const onTimeUpdate = (e: SyntheticEvent): void => {
    const target = e.target as unknown as HTMLMediaElement;
    setPosition(target.currentTime);
  };
  const disableRightClick = (e: SyntheticEvent): void => e.preventDefault();
  const onVideoMetaData = (e: SyntheticEvent): void => {
    const target = e.target as unknown as HTMLMediaElement;

    setError(null);
    setMute(target.muted);
    setDuration(target.duration);
  };

  const errorHandler = (): void => {
    setCanPlay(true);
  };

  const handleVideoExport = (): void => {
    if (downloading) return;
    if (onExport) return onExport(video?.src || url);
    EXPORT_DEFAULT()
      .catch(() => setError(t('common:component.video-modal.hint.download-error')));
  };

  useEffect(() => {
    if (open) return;
    video?.pause();
  }, [open, video]);

  useEffect(() => {
    if (!error) return;
    enqueueSnackbar(<ErrorMessage error={error} />, { variant: 'error' });
    setError(null);
  }, [error]);

  const VideoContent = (): JSX.Element => <>
    {!canPlay
      && <FlexBox width="640px" height="360px" sx={{ justifyContent: 'center' }}>
        <MiddleSpinner />
      </FlexBox>
    }
    <Fade in={canPlay}>
      <Stack
        ref={videoWrapper}
        className={classes.root}
        style={{
          ...((controls.close && (!open || !canPlay)) && { display: 'none' }),
          overflow: 'hidden',
        }}
      >
        {controls.close
          && <Paper className={classes.actionCard} elevation={1}>
            <IconButton
              aria-label={t('common:common.action.close')}
              onClick={onModalClick}
              size="small"
              sx={{
                color: (theme) => theme.palette.common.white,
              }}
              data-testid='video-player-close'
            >
              <CloseIcon />
            </IconButton>
          </Paper>
        }
        <VideoComponent
          key={url}
          ref={videoElement}
          autoPlay={true}
          className={classes.video}
          fixedSize={fixedSize}
          fullscreen={fullscreen}
          muted={true}
          onCanPlay={() => setCanPlay(true)}
          onClick={onClick}
          onContextMenu={disableRightClick}
          onDoubleClick={fullScreen}
          onError={errorHandler}
          onLoadedMetadata={onVideoMetaData}
          onPause={onVideoPause}
          onPlaying={onVideoPlaying}
          onTimeUpdate={onTimeUpdate}
          playsInline={true}
          preload="metadata"
          src={url}
          data-testid={props['data-testid']}
        />
        <div
          className={classes.videoPlayerControllers}
          style={{
            bottom: 0,
            display: 'flex',
            justifyContent: 'space-around',
            transition: `opacity linear ${Math.max(0, fadeTimer)}ms`,
            width: '100%',
          }}
        >
          <RowItem
            style={{ height: '2em', lineHeight: '2em', width: '2em' }}
          >
            <RowButtons
              disabled={!(controls.play && controls.pause)}
              handler={playHandler}
            >
              {play ? <PlayCircleIcon /> : <PauseCircleIcon />}
            </RowButtons>
          </RowItem>
          <div
            style={{
              padding: '0 .5em',
              width: smallScreen ? '60%' : '100%',
            }}
          >
            <StyledSlider
              aria-label={t('common:component.video-player.hint.time-label')}
              color="secondary"
              componentsProps={{
                root: {
                  style: { overflow: 'hidden' },
                },
              }}
              defaultValue={0}
              disabled={error !== null || !controls.seek}
              max={duration}
              min={0}
              onChange={onVideoSeek}
              value={position}
            />
          </div>
          <RowItem style={{ height: '2em', lineHeight: '2em', width: '6em' }}>
            <Typography variant="caption">{`${toHHMMSS(position)} / ${toHHMMSS(duration)}`}</Typography>
          </RowItem>
          <RowItem
            style={{ height: '2em', lineHeight: '2em', width: smallScreen ? '6em' : '9rem' }}
          >
            <Grid display="row" item style={{ textAlign: 'right' }}>
              {!smallScreen
                && <RowButtons data-testid="download-video-button" disabled={downloading ?? !controls.export} handler={handleVideoExport}>
                  {downloading
                    ? <MiddleSpinner size={20} />
                    : <Download />
                  }
                </RowButtons>
              }
              <RowButtons disabled={!controls.volume} handler={muteHandler}>
                {mute ? <VolumeOffIcon /> : <VolumeUpIcon />}
              </RowButtons>
              <RowButtons disabled={!controls.fullScreen} handler={fullScreen}>
                {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </RowButtons>
            </Grid>
          </RowItem>
        </div>
      </Stack>
    </Fade>
  </>;

  const VideoContentMemo = useMemo(VideoContent, [
    classes,
    controls,
    disableRightClick,
    duration,
    error,
    fullScreen,
    fullscreen,
    height,
    muteHandler,
    onClick,
    onPause,
    onplaying,
    onTimeUpdate,
    onVideoMetaData,
    playHandler,
    position,
    smallScreen,
    url,
    video,
    videoElement,
    videoWrapper,
    width,
  ]);

  return VideoContentMemo;
};
