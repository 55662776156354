import { TypeDefinitionTypes } from '@eagle/common';
import { Divider, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ulid } from 'ulid';
import { addLocalStorageItem, testid } from '../../util';
import { AppliedFilter, AppliedFilterType, EntityField, FilterFieldNew, FilterOptions, SelectedFilterType } from '../entity-search';
import { FILTERS_KEY, FilterTypes } from '../filter';
import RadioButtonSet, { RadioButtonOption } from '../radio-button-set/radio-button-set';
import { FilterAttributesInput } from './filter-attributes-input';
import { NewFilterInput } from './new-filter-input';

interface Props {
  field: FilterFieldNew;
  filterOptions: FilterOptions;
  filters: AppliedFilter[];
  includeDeleted?: boolean;
  onFiltersChanged?: (filters: AppliedFilter[]) => unknown;
  storageKey: string;
}

enum FilterRadioButtons {
  BY_ATTRIBUTES = 'by-attributes',
  BY_ENTITY = 'by-entity',
}

export const FilterInputItem: FC<Props> = ({ field, filterOptions, filters, includeDeleted, onFiltersChanged, storageKey }) => {
  const { t } = useTranslation(['common']);
  const options: RadioButtonOption[] = [
    {
      label: t('common:common.labels.by-attributes'),
      value: FilterRadioButtons.BY_ATTRIBUTES,
    },
    {
      label: t('common:common.labels.by-entity', { entity: field.definition.label }),
      value: FilterRadioButtons.BY_ENTITY,
    },
  ];
  const [selected, setSelected] = useState(options[field.attributes ? 0 : 1].value);

  const isSelectedEntityFilterAlreadyApplied = (
    value: EntityField,
  ): boolean => {
    return !!filters
      .filter((filter) => filter.definition.type === 'entity')
      .find((filter) =>
        filter.propertyPath === (field.replacePath ?? field.path)
        && value.id === (filter.value as EntityField).id,
      );
  };

  const submitFilter = (selectedField: FilterFieldNew, valueText: SelectedFilterType): void => {
    const updatedPath = selectedField.replacePath ?? selectedField.path;
    const createAppliedFilter = (
      value: AppliedFilterType,
    ): AppliedFilter => {
      return {
        id: ulid(),
        definition: selectedField.definition,
        pathIdentifier: selectedField.pathIdentifier,
        propertyPath: updatedPath,
        ids: selectedField.ids,
        value,
      };
    };

    if (selectedField.definition.type === 'entity' || selectedField.definition.type === TypeDefinitionTypes.REFERENCE) {
      const filtersRelatedIds = filters.filter(({ propertyPath }) => propertyPath === updatedPath).map(({ value }) => (value as EntityField).id);
      const idToRemove = filtersRelatedIds.filter((id) => !(valueText as EntityField[]).find((item) => item.id === id));

      const filtersToAdd = (valueText as EntityField[])
        .map(
          (v) => createAppliedFilter(v) as AppliedFilter<EntityField>,
        )
        .filter(
          (filter) => {
            return !isSelectedEntityFilterAlreadyApplied(filter.value);
          },
        );

      const updatedFilters = selectedField.definition.type === TypeDefinitionTypes.REFERENCE ?
        filters :
        filters.filter(({ value }) => !idToRemove.includes((value as EntityField).id));

      addLocalStorageItem(FILTERS_KEY, {
        [storageKey]: [
          ...updatedFilters,
          ...filtersToAdd,
        ],
      });

      onFiltersChanged?.([
        ...updatedFilters,
        ...filtersToAdd,
      ]);

      return;
    }

    addLocalStorageItem(FILTERS_KEY, {
      [storageKey]: [
        ...filters,
        createAppliedFilter(valueText as AppliedFilterType),
      ],
    });

    onFiltersChanged?.([
      ...filters,
      createAppliedFilter(valueText as AppliedFilterType),
    ]);
  };

  const appliedFilters = (filterType: FilterTypes): AppliedFilterType[] => {
    return filters
      .filter(({ propertyPath }) => propertyPath === filterType)
      .map(({ value }) => value);
  };

  return (
    <Stack spacing={1}>
      <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography>{field.definition.label}</Typography>
        {!field.omitEntityFilter && field.attributes
          && <RadioButtonSet
            options={options}
            selected={selected}
            setSelected={setSelected}
            data-testid={testid`${field.definition.label}-filter`}
          />
        }
      </Stack>
      {selected === FilterRadioButtons.BY_ENTITY
        && <NewFilterInput
          apiUrl={field.apiUrl}
          appliedFilters={appliedFilters}
          dataType={field.dataType}
          entityCache={field.entityCache}
          findItems={field.findItems}
          includeDeleted={includeDeleted}
          inputError={false}
          isApplied={isSelectedEntityFilterAlreadyApplied}
          label={field.fieldLabel}
          onChanged={(value) => submitFilter(field, value)}
          path={field.path}
          placeholderText={filterOptions.valuePlaceholderText}
          replacePath={field.replacePath}
          type={field.definition.type}
          values={field.values}
          data-testid={testid`${field.definition.label}-filter`}
        />
      }
      {selected === FilterRadioButtons.BY_ATTRIBUTES && field.attributes
        && <FilterAttributesInput
          appliedFilters={appliedFilters}
          entityTypeCache={field.attributes.typeCache}
          entityTypePath={field.attributes.typePath}
          filterEntityType={field.attributes.filterEntityType}
          getEntityTypeId={field.attributes.getEntityTypeId ?? ((entity) => entity._id)}
          includeDeleted={includeDeleted}
          pathIdentifier={field.attributes.pathIdentifier ?? field.pathIdentifier}
          propertyPathPrefix={field.attributes.propertyPathPrefix}
          submitFilter={submitFilter}
          transformProperties={field.attributes.transformProperties}
          data-testid="type-select-text-input"
        />
      }
      <Divider />
    </Stack>
  );
};
