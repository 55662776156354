import axios from 'axios';
import { HERE_MAP_API_KEY } from '../constants';
import { Nullable } from '../types';

interface WeatherResponse {
  places: [{
    observations: Record<string, string | number>[];
  }];
}

export const getLocationWeather = async (latlng: L.LatLng, units?: 'metric' | 'imperial', apiKey?: string): Promise<Nullable<Record<string, string | number>>> => {
  const url = 'https://weather.hereapi.com/v3/report';
  const response = await axios.get<WeatherResponse>(url, {
    params: {
      location: `${latlng.lat},${latlng.lng}`,
      apiKey: apiKey ?? HERE_MAP_API_KEY,
      products: 'observation',
      units,
      oneObservation: true,
    },
  });
  const data = response.data;

  if (data.places[0].observations[0]) {
    return data.places[0].observations[0];
  }

  return null;
};

export const mapWeatherIcon = (hereIconId: number): string => {
  let icon = 'UnknownWeatherIcon';

  switch (hereIconId) {
    case 1:
    case 2:
      icon = 'SunIcon';
      break;
    case 3:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 27:
      icon = 'HazeIcon';
      break;
    case 4:
    case 5:
    case 6:
    case 7:
    case 15:
    case 16:
    case 17:
      icon = 'CloudIcon';
      break;
    case 13:
    case 14:
      icon = 'NightIcon';
      break;
    case 18:
    case 34:
      icon = 'DrizzleIcon';
      break;
    case 19:
    case 20:
    case 28:
    case 32:
    case 33:
      icon = 'RainIcon';
      break;
    case 21:
    case 22:
    case 23:
    case 35:
      icon = 'ThunderIcon';
      break;
    case 24:
    case 25:
      icon = 'TornadoIcon';
      break;
    case 29:
    case 30:
    case 31:
      icon = 'SnowIcon';
      break;
  }

  return icon;
};
