/* eslint-disable react-hooks/exhaustive-deps */
import { RoleFunction } from '@eagle/common';
import { Account, AccountType } from '@eagle/core-data-types';
import { Card, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import axiosStatic from 'axios';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHref, useNavigate } from 'react-router-dom';
import { DomainCustomConfig, useAuthenticated } from '../../auth';
import { FetchOneOfAll, FlexBox, MiddleSpinner, Pagination, PortalFeatureIcons, ResultsDetailCard, ResultsTableRow, SearchResultsTableView, useBoolFlag } from '../../components';
import { T_MANY, T_ONE } from '../../constants';
import { useDynamicModule, usePromise, useSmallScreen } from '../../hooks';
import { CacheDataTypes, FeatureIcons, PageAction, Undefinable } from '../../types';
import { BoldMatchedText, getListResultDescription, includeDeletedFilters, loadConfig, useHasAuthorization } from '../../util';
import { FindItemsDeferredResult, ListPage, ListPageQuery } from '../list';
import { AccountRowButton } from './account-row-button';

const NUMBER_OF_COLUMNS = 1;
export const COLUMN_WIDTH = `${100 / NUMBER_OF_COLUMNS}%`;

const ACCOUNT_CREATE_ROLE = [RoleFunction.ACCOUNT_CREATOR] as const;

export const AccountsList: FC = () => {
  const { restClient, userInfo } = useAuthenticated();
  const smallScreen = useSmallScreen();
  const { t } = useTranslation(['common']);
  const itemType = t('common:terms.account', { count: T_MANY });
  const navigate = useNavigate();

  const href = useHref('/account');
  const { hasAuthorization } = useHasAuthorization();
  const showCreateButton = useBoolFlag('manage-account-create-feature') && hasAuthorization(ACCOUNT_CREATE_ROLE);
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Admin);
  const [domainConfigs, setDomainConfigs] = useState<Record<string, Partial<DomainCustomConfig>>>({});

  const findAccounts = useCallback(({ search, pagination, showDeleted }: ListPageQuery): FindItemsDeferredResult<Account> => {
    const cancelToken = axiosStatic.CancelToken.source();

    return {
      cancel: cancelToken.cancel,
      promise: restClient.account.getAllV1Paged({
        ...pagination,
        ...(search ? { search } : {}),
        filter: { _id: { $ne: userInfo.accountId }, ...includeDeletedFilters(showDeleted) },
        sort: !search ? JSON.stringify({ display: 'asc' }) : undefined,
      }, { cancelToken: cancelToken.token }).then((response) => {
        const matchCount = response.count;

        const resultDescription = getListResultDescription({ count: matchCount, entityKey: 'common:terms.account', search, t });

        const homeDomains = [...response.entities.reduce((set, account) => set.add(account.homeDomain), new Set<string>())];

        Promise.all(homeDomains.map((homeDomain) => loadConfig(true, homeDomain))).then((domains) => {
          setDomainConfigs(homeDomains.reduce((obj, homeDomain, index) => ({ ...obj, [homeDomain]: domains[index] }), {}));
        }).catch(() => { });

        return {
          result: {
            itemCount: matchCount,
            results: response.entities,
          },
          resultDescription,
        };
      }),
    };
  }, [restClient]);

  const [myAccounts] = usePromise(async () => {
    return restClient.my.accounts.getAll();
  }, [restClient]);

  const navigateToAccount = (account: Account): void => {
    navigate(`${href}/${encodeURIComponent(account._id)}`);
  };

  const renderTableRowContent = (item: Account): JSX.Element => {
    const showSwitchAccount = !item.deleted && !!myAccounts?.some((account) => account._id === item._id);
    const productName = domainConfigs[item.homeDomain]?.productName;

    if (smallScreen) {
      return <FlexBox sx={{ width: 1, alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}><BoldMatchedText text={item.display} /></Typography>
        {showSwitchAccount && <AccountRowButton accountId={item._id} />}
      </FlexBox>;
    }
    return <>
      <TableCell component="div">
        <Typography><BoldMatchedText text={item.display} /></Typography>
      </TableCell>
      <TableCell component="div">
        {item.accountTypeId
          ? <FetchOneOfAll
            dataType={CacheDataTypes.ACCOUNT_TYPE}
            id={item.accountTypeId}
            renderFactory={(accountType: AccountType) => <Typography><BoldMatchedText text={accountType.display} /></Typography>}
          />
          : <Typography color="text.secondary" fontStyle="italic" variant="body2">
            {t('common:page.accounts-list.no-account-type.labels')}
          </Typography>
        }
      </TableCell>
      <TableCell component="div">
        <Typography><BoldMatchedText text={item.homeDomain} /></Typography>
        {productName
          ? <Typography><BoldMatchedText text={productName} /></Typography>
          : <Typography color="text.secondary" fontStyle="italic" variant="body2">
            {t('common:page.accounts-list.no-product-name.labels')}
          </Typography>
        }
      </TableCell>
      <TableCell component="div" sx={{ width: 200 }}>
        {showSwitchAccount && <AccountRowButton accountId={item._id} />}
      </TableCell>
    </>;
  };

  const renderDesktopContent = (isLoading: boolean, items: Account[]): JSX.Element => <>
    <TableHead component="div">
      <TableRow component="div">
        <TableCell component="div">{t('common:terms.name')}</TableCell>
        <TableCell component="div">{t('common:page.accounts-list.account-type.labels')}</TableCell>
        <TableCell component="div">{t('common:page.accounts-list.home-domain.labels')}</TableCell>
        <TableCell component="div"></TableCell>
      </TableRow>
    </TableHead>
    <TableBody component="div" sx={{ filter: isLoading ? 'blur(1px)' : '', opacity: isLoading ? 0.66 : 1 }}>
      {items.map((item, i) => (
        <ResultsTableRow
          data-testid={`account-list-result-item-${i}`}
          key={i}
          deleted={item.deleted !== null}
          content={renderTableRowContent(item)}
          href={`${href}/${item._id}`}
        />
      ))}
    </TableBody>
  </>;

  const renderMobileContent = (_displayOnCard: boolean, isLoading: boolean, items: Account[]): JSX.Element => <>
    {items.map((item, i) => {
      return (
        <Card key={i} sx={{ filter: isLoading ? 'blur(1px)' : '', my: 1, overflow: 'visible', width: '100%' }}>
          <ResultsDetailCard
            content={renderTableRowContent(item)}
            href={`${href}/${item._id}`}
            sx={{ p: 2 }}
            deleted={item.deleted !== null}
            data-testid={`account-list-result-item-${i}`}
          />
        </Card>
      );
    })}
  </>;

  const renderTableContent = (
    isLoading: boolean,
    items: Undefinable<Account[]>,
    matchCount: number,
    pagination: Pagination,
    setPagination: (value: Pagination) => void,
    text: Undefinable<string>,
  ): JSX.Element => {
    return (
      <SearchResultsTableView<Account>
        isLoading={isLoading}
        items={items}
        matchCount={matchCount}
        noResultsInstructions={t('common:common.hint.list.none', { entity: itemType })}
        pagination={pagination}
        renderDesktopContent={(isLoading, items) => renderDesktopContent(isLoading, items)}
        renderMobileContent={(displayOnCard, isLoading, items) => renderMobileContent(displayOnCard, isLoading, items)}
        setPagination={setPagination}
        text={text}
      />
    );
  };

  const actions: PageAction[] = [];

  if (showCreateButton) {
    actions.push({
      href: `${href}/create`,
      icon: module?.AccountIcon && <module.AccountIcon />,
      label: t('common:common.action.create-entity', { entity: t('common:terms.account', { count: T_ONE }) }),
      onClick: () => navigate(`${href}/create`),
    });
  }

  if (!moduleLoaded) return <MiddleSpinner />;

  return (
    <ListPage<Account>
      data-testid='account-list'
      actions={actions}
      filterFields={[]}
      icon={module?.AccountIcon && <module.AccountIcon />}
      navigateToEntity={navigateToAccount}
      onQueryChanged={findAccounts}
      pagination={{ limit: 50, skip: 0 }}
      renderTableContent={renderTableContent}
      showFilterButton={false}
      storageKey="accounts"
      title={t('common:terms.account', { count: T_MANY })}
    />
  );
};
