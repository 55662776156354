import { PaginatedResponse } from '@eagle/api-types';
import { RoleFunction } from '@eagle/common';
import { getThingFeatureCombinations, MediaSegmentData, MediaType, Thing, ThingEventSnapshot, ThingType } from '@eagle/core-data-types';
import { FC } from 'react';
import { useAuthenticated } from '../../auth';
import { CacheDataTypes, Undefinable } from '../../types';
import { FetchOne, FetchOneOfAll } from '../fetch';
import { MediaCard, MediaListPageType } from '../media-data';
import { EventDialogController } from './event-dialog-controller';
import { getNavigateState } from './event-dialog.util';

interface Props {
  closeDialog: () => void;
  'data-testid'?: string;
  eventIndex: number;
  events: ThingEventSnapshot[];
  open: boolean;
  setEventIndex: (index: number) => void;
}

export const ThingEventDialog: FC<Props> = ({
  closeDialog,
  eventIndex,
  events,
  open,
  setEventIndex,
  ...props
}) => {
  const { restClient, userInfo } = useAuthenticated();
  const hasMediaPermissions = userInfo.hasRoleFunction(RoleFunction.MEDIA_VIEWER);

  const maxEventIndex = events.length - 1;
  const eventsBoundaryCheck = eventIndex > maxEventIndex ? maxEventIndex : eventIndex;

  const handleLoadMediaData = (event: ThingEventSnapshot, features: string[]) => {
    return async (_: Undefinable<string>, mediaType: Undefinable<MediaType>): Promise<PaginatedResponse<MediaSegmentData>> => {
      const status = mediaType === MediaType.MP4 ? { status: 'upload-complete' } : {};
      const mediaData = await restClient.mediaData.getAllV2({
        filter: {
          '$and': [{
            ...status,
            feature: { '$or': features },
            featureTypeId: 'camera-v0',
            finish: mediaType === MediaType.JPEG ? event.occurred : { '$gt': event.occurred },
            mediaType,
            start: mediaType === MediaType.JPEG ? event.occurred : { '$lt': event.occurred },
            thingId: event.thingId,
          }],
        },
      });
      return mediaData;
    };
  };

  const renderMediaComponent = hasMediaPermissions ? (event: ThingEventSnapshot): JSX.Element => (
    <FetchOne
      id={events[eventsBoundaryCheck].thingId}
      dataType={CacheDataTypes.THING}
      renderFactory={({ _id, display, thingTypeId }: Thing) =>
        <FetchOneOfAll
          id={thingTypeId}
          dataType={CacheDataTypes.THING_TYPE}
          renderFactory={({ features }: ThingType) => {
            const cameraFeature = features.find((feature) => feature.featureId === 'camera');
            const cameraFeatures = cameraFeature ? getThingFeatureCombinations(cameraFeature) : [];

            return (
              <MediaCard
                eventIndex={eventsBoundaryCheck}
                entity={{ _id, display }}
                entityTypeFeatures={features}
                getMediaData={handleLoadMediaData(event, cameraFeatures)}
                navigateState={getNavigateState(event.occurred)}
                pageType={MediaListPageType.ALERTS}
              />
            );
          }}
        />
      }
    />
  ) : undefined;

  if (!events.length) return <></>;

  return (
    <EventDialogController<ThingEventSnapshot>
      closeDialog={closeDialog}
      data-testid={props['data-testid']}
      eventIndex={eventsBoundaryCheck}
      events={events}
      open={open}
      renderMediaComponent={renderMediaComponent}
      setEventIndex={setEventIndex}
    />
  );
};
