import { Skeleton } from '@mui/material';
import { isNumber } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useConfig } from '../../hooks';
import { getLocationWeather, mapWeatherIcon } from '../../util';
import { Icon } from '../icons/widget-icon';

interface Props {
  latLng: L.LatLng;
}

export const CurrentWeatherIcon: FC<Props> = ({ latLng }) => {
  const [value, setValue] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const config = useConfig();

  useEffect(() => {
    const fetchWeather = async (): Promise<void> => {
      try {
        setLoading(true);
        const data = await getLocationWeather(latLng, 'metric', config.hereMaps?.apiKey);
        const iconId = (data && isNumber(data?.iconId)) ? data.iconId : 0;
        setValue(mapWeatherIcon(iconId));
      } catch (error) {
        setValue(mapWeatherIcon(0));
      } finally {
        setLoading(false);
      }
    };

    fetchWeather().catch(() => { });
  }, [latLng, config]);

  if (isLoading || !value) {
    return <Skeleton variant='circular' width='1em' height='1em' sx={{ display: 'inline-block' }} />;
  }

  return <Icon icon={value} />;
};
