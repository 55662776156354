import { Skeleton } from '@mui/material';
import { get, isNumber, isString } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useConfig } from '../../hooks';
import { getLocationWeather } from '../../util';

interface Props {
  append?: string;
  latLng: L.LatLng;
  path: string;
  units?: 'metric' | 'imperial';
  fallback?: string;
  allowDecimal?: boolean;
}

export const WeatherField: FC<Props> = ({ allowDecimal, append = '', fallback, latLng, path = 'temperature', units }) => {
  const [value, setValue] = useState<string | number | null>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const config = useConfig();

  useEffect(() => {
    setLoading(true);
    getLocationWeather(latLng, units, config.hereMaps?.apiKey)
      .then((data) => {
        const fieldInfo = get(data, path);

        if (isNumber(fieldInfo) && !allowDecimal) {
          setValue(Math.round(fieldInfo));
          return;
        }

        setValue(fieldInfo);
      })
      .catch(() => {
        setValue(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [allowDecimal, latLng, path, config, units]);

  if (isLoading) {
    return <Skeleton />;
  }

  if (!isString(path) || !value) {
    return fallback ? <>{fallback}</> : null;
  }

  return <>{`${value}${append}`}</>;
};
